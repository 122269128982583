<template>
  <div>
    <b-alert
      :variant="alertData.variant"
      :show="dismissCountDown"
      dismissible
      fade
      class="mb-1"
      @dismiss-count-down="countDownChanged"
    >
      <div class="alert-body">
        <span><strong>{{ alertData.strongText }}</strong> {{ alertData.lightText }}</span>
      </div>
    </b-alert>

    <b-card>
      <b-button
        variant="primary"
        @click="goToCreatePage"
      >
        <feather-icon icon="PlusCircleIcon" />
        {{ $t('assets.new') }}
      </b-button>
    </b-card>
    <b-card no-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="readItems"
        :fields="fields"
      >
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>
        <template #cell(nickname)="data">
          {{ data.item.nickname }}
        </template>
        <template #cell(createdAt)="data">
          {{ new Date(data.item.createdAt).toLocaleDateString() }}
        </template>
        <template #cell(action)="data">
          <b-link
            v-if="data.item.id > 2"
            @click="deleteAlert(data.item.id)"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
              size="20"
            />
          </b-link>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAlert,
  BLink,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BAlert,
    BLink,
    BButton,
  },
  data() {
    return {
      items: [],
      // Alert
      dismissSecs: 4,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      alertData: {
        lightText: null,
        strongText: null,
        variant: 'danger',
      },
    }
  },
  computed: {
    readItems() { return this.items },
    fields() {
      return [
        { key: 'name', label: this.$t('assets.table.name'), sortable: true },
        { key: 'nickname', label: this.$t('assets.table.nickname'), sortable: true },
        { key: 'createdAt', label: this.$t('assets.table.createdAt'), sortable: true },
        { key: 'action', label: this.$t('assets.table.action'), sortable: true },
      ]
    },
  },
  async created() { await this.load() },
  methods: {
    showAlert(alertProps, variant) {
      this.alertData = alertProps
      this.alertData.variant = variant
      this.dismissCountDown = this.dismissSecs
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    deleteAlert(id) {
      this.$swal({
        ...this.$t('assets.toast.deleteAsset'),
        buttonsStyling: false,
        showDenyButton: true,
        customClass: { confirmButton: 'btn btn-danger mr-1', denyButton: 'btn btn-primary' },
      }).then((result => {
        if (!result.isConfirmed) { return }
        this.deleteAsset(id)
          .then(() => {
            this.showAlert(this.$t('assets.removeOkAlert.props'), 'success')
            this.load()
          }).catch(() => { this.showAlert(this.$t('assets.removeErrorAlert.props'), 'danger') })
      }))
    },
    deleteAsset(id) { return this.$store.dispatch('asset/delete', id) },
    goToCreatePage() { this.$router.push({ name: 'pages-asset-create' }) },
    async load() {
      this.items = await this.$store.dispatch('asset/index')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@media (max-width: 767px){
  .small-devices-filterby{
    display: block;
  }
}
.dropdown button {
    padding: 0px;
}
.em-dropdown .dropdown-menu {
  max-height: 100px;
  overflow-y: hidden;
}
.dropdown-item{
  padding: 0 4px;
}
</style>
